import { keyBy, noop } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { Branding_BrandedFragment } from '@/modules/admin/branding/graphql/Branding.generated';
import { TenantKind, TenantUsageBucket } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import {
  AssetClassFragment,
  IntegrationConfigurationFragment,
  TenantApplicationConfigurationFragment,
  useTenantInformationQuery,
} from '../graphql/TenantInformation.generated';

export interface TenantDetails {
  id: string;
  kind: TenantKind | null;
  usageBucket: TenantUsageBucket;
  applicationConfiguration: TenantApplicationConfigurationFragment | null;
  integrationConfiguration: IntegrationConfigurationFragment | null;
  branding: Branding_BrandedFragment | null;
  displayName: string | null;
  subBrandsById: Record<string, Branding_BrandedFragment>;
  activeSubBrandId: string | null;
  setActiveSubBrandId: (id: string | null) => void;
  hasSubBrands: boolean;
  /**
   * Expose asset classes by id so we can show the display names
   * for each asset class throughout the app
   */
  assetClassesById: Record<string, AssetClassFragment>;
  hasCustomOpenAIAPIKey: boolean;
}

const defaultTenantDetails: TenantDetails = {
  id: '',
  usageBucket: TenantUsageBucket.Internal,
  applicationConfiguration: null,
  integrationConfiguration: null,
  kind: null,
  branding: null,
  displayName: null,
  subBrandsById: {},
  activeSubBrandId: null,
  setActiveSubBrandId: noop,
  hasSubBrands: false,
  assetClassesById: {},
  hasCustomOpenAIAPIKey: false,
};

export const TenantDetailsContext =
  React.createContext<TenantDetails>(defaultTenantDetails);

export function TenantDetailsProvider({ children }: React.PropsWithChildren) {
  const [activeSubBrandId, setActiveSubBrandId] = useState<string | null>(null);

  const { data } = useTenantInformationQuery({
    // this shouldn't need to go to the network, but in a scenario when the cache is fully cleared
    // for some reason, we don't want to prevent it from doing so
    fetchPolicy: 'cache-first',
  });

  const subBrands = getNodes(data?.subBrands);
  const subBrandsById = useMemo(() => {
    return keyBy(subBrands, 'id');
  }, [subBrands]);

  const handleSetActiveSubBrandId = useCallback(
    (id: string | null) => {
      if (id === activeSubBrandId) return;
      setActiveSubBrandId(id);
    },
    [activeSubBrandId]
  );

  const finalTenantDetails: TenantDetails = useMemo(() => {
    if (!data?.tenantInformation) {
      return defaultTenantDetails;
    }

    const tenantDetails = data.tenantInformation;
    const assetClasses = getNodes(data?.assetClasses);
    const assetClassesById = keyBy(assetClasses, 'id');

    return {
      ...(tenantDetails ?? {}),
      applicationConfiguration: data?.tenantApplicationConfiguration ?? null,
      integrationConfiguration: data?.integrationConfiguration ?? null,
      displayName: tenantDetails?.branding.displayName ?? null,
      subBrandsById,
      activeSubBrandId,
      setActiveSubBrandId: handleSetActiveSubBrandId,
      hasSubBrands: subBrands.length > 0,
      assetClassesById,
      hasCustomOpenAIAPIKey:
        data?.integrationConfiguration?.hasCustomOpenAIAPIKey ?? false,
    };
  }, [
    data,
    subBrandsById,
    activeSubBrandId,
    handleSetActiveSubBrandId,
    subBrands.length,
  ]);

  return (
    <TenantDetailsContext.Provider value={finalTenantDetails}>
      {children}
    </TenantDetailsContext.Provider>
  );
}

export const useTenantDetailsContext = () => {
  return useGuardedContext(TenantDetailsContext, 'TenantDetailsProvider');
};
